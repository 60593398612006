<template>
  <div class="saleSampleSettlement">
    <h1>销售结算</h1>
    <el-form :inline="true">
      <el-form-item label="收货日期">
        <el-date-picker v-model="searchParam.sampleDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="结算状态">
        <el-select v-model="searchParam.saleSettlementIdIsNull" filterable style="width: 120px;" clearable>
          <el-option label="未结算" :value="true" />
          <el-option label="已结算" :value="false" />
        </el-select>
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" :disabled="!(show.selected.length >= 1)" @click="show.settlementFormulaDialog = true">结算</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-text>已选中</el-text>
    <el-text style="margin-left: 10px;">总数量：</el-text>
    <el-text>{{ show.selectedSumQuantity }}</el-text>
    <el-text style="margin-left: 10px;">平均硫：</el-text>
    <el-text>{{ show.selectedQuanliu }}</el-text>
    <el-text style="margin-left: 10px;">平均热·大卡：</el-text>
    <el-text>{{ show.selectedDiweifareliangKc }}</el-text>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border style="height: calc(100vh - 310px);">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column prop="saleAmount" label="结算金额" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.saleAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="sampleDate" label="取样日期" width="120" show-overflow-tooltip />
      <el-table-column prop="truckNumbers" label="车号" width="120" show-overflow-tooltip />
      <el-table-column prop="storage" label="仓储" width="120" show-overflow-tooltip />
      <el-table-column prop="custom" label="客户" width="120" show-overflow-tooltip />
      <el-table-column prop="customNote" label="销售品名" width="120" show-overflow-tooltip />
      <el-table-column prop="saleMixDetailList" label="配比" width="300" show-overflow-tooltip>
        <template #default="scope">
          <span v-for="(item, index) in scope.row.saleMixDetailList" :key="item.product">
            <el-text style="margin: 0 10px;" v-if="index != 0">:</el-text>
            <el-text>{{ item.product + '[' + item.mixNumber + ']' + '(' + item.sendQuantity + ')' }}</el-text>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="sampleQuantity" label="取样数量" width="120" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.sampleQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="sampleType" label="类型" width="120" show-overflow-tooltip />
      <el-table-column prop="quanshui" label="全水" width="120" show-overflow-tooltip />
      <el-table-column prop="neishui" label="内水" width="120" show-overflow-tooltip />
      <el-table-column prop="huifen" label="灰分" width="120" show-overflow-tooltip />
      <el-table-column prop="huifafen" label="挥发分" width="120" show-overflow-tooltip />
      <el-table-column prop="quanliu" label="全硫" width="120" show-overflow-tooltip />
      <el-table-column prop="gudingtan" label="固定碳" width="120" show-overflow-tooltip />
      <el-table-column prop="diweifareliangMj" label="热值·兆焦" width="120" show-overflow-tooltip />
      <el-table-column prop="diweifareliangKc" label="热值·大卡" width="120" show-overflow-tooltip />
    </el-table>
    <div style="height: 10px;" />
    <el-pagination v-model:current-page="searchParam.pageNum" v-model:page-size="searchParam.pageSize" :page-sizes="[50, 100, 200, 1000]" background layout="total, sizes, prev, pager, next, jumper" :total="data.total" @change="commitFindList()" />
  </div>

  <el-dialog v-model="show.settlementFormulaDialog" title="选择结算公式" width="500">
    <el-table :data="show.settlementFormulaList" :show-header="false">
      <el-table-column prop="name" />
      <el-table-column prop="btn" width="100">
        <template #default="scope">
          <el-button style="width: 100%;" @click="useSettlementFormula(scope.row)">使用</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
  sampleDates: []
})

const show = reactive({
  selected: [],
  selectedSumQuantity: 0,
  selectedQuanliu: 0,
  selectedDiweifareliangKc: 0,
  customList: [],
  storageList: [],
  settlementFormulaDialog: false,
  settlementFormulaList: []
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/formula/get', { params: { model: 'saleSettlement' } }).then(res => {
  show.settlementFormulaList = res.list
})

const searchParam = reactive({})
let now = new Date()
const resetSearchParam = () => {
  searchParam.pageNum = 1
  searchParam.pageSize = 1000
  searchParam.sampleDates = [now, now]
  searchParam.saleSettlementIdIsNull = null
  searchParam.custom = null
  searchParam.storage = null
}
resetSearchParam()

const commitFindList = () => {
  const params = {}
  params.pageNum = searchParam.pageNum
  params.pageSize = searchParam.pageSize
  if (searchParam.sampleDates && searchParam.sampleDates.length == 2) {
    params.sampleDateGe = util.parseTime(searchParam.sampleDates[0], '{y}-{m}-{d}')
    params.sampleDateLe = util.parseTime(searchParam.sampleDates[1], '{y}-{m}-{d}')
  } else {
    ElMessage.error('请选择日期')
    return
  }
  params.saleSettlementIdIsNull = searchParam.saleSettlementIdIsNull
  params.custom = searchParam.custom
  params.storage = searchParam.storage
  const dataSampleDates = searchParam.sampleDates
  api.get('/backend/saleSample/page', { params: params }).then(res => {
    data.list = res.list
    data.total = res.total
    data.sampleDates = dataSampleDates
  })
}
commitFindList()

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
  let sumQuantity = 0
  let sumQuanliu = 0
  let sumDiweifareliangKc = 0
  for (const i in show.selected) {
    const item = show.selected[i]
    const itemSampleQuantity = item.sampleQuantity || 0
    const itemDiweifareliangKc = item.diweifareliangKc || 0
    const itemQuanliu = item.quanliu || 0
    sumQuantity = math.plus(sumQuantity, itemSampleQuantity)
    sumQuanliu = math.plus(sumQuanliu, math.multiply(itemQuanliu, itemSampleQuantity))
    sumDiweifareliangKc = math.plus(sumDiweifareliangKc, math.multiply(itemDiweifareliangKc, itemSampleQuantity))
  }
  show.selectedSumQuantity = sumQuantity
  if (sumQuantity != 0) {
    show.selectedQuanliu = math.halfUp(sumQuanliu / sumQuantity, 2)
    show.selectedDiweifareliangKc = math.halfUp(sumDiweifareliangKc / sumQuantity, 2)
  } else {
    show.selectedQuanliu = 0
    show.selectedDiweifareliangKc = 0
  }
}

const useSettlementFormula = (formula) => {
  const param = {}
  if (data.sampleDates && data.sampleDates.length == 2) {
    param.settlementIntervalFrom = util.parseTime(data.sampleDates[0], '{y}-{m}-{d}')
    param.settlementIntervalTo = util.parseTime(data.sampleDates[1], '{y}-{m}-{d}')
  } else {
    ElMessage.error('请选择日期')
    return
  }
  const saleSampleList = JSON.parse(JSON.stringify(tabRef.value.getSelectionRows()))
  for (const i in saleSampleList) {
    const saleSample = saleSampleList[i]
    try {
      let 结果 = null
      const 取样数量 = saleSample.sampleQuantity
      const 全水 = saleSample.quanshui
      const 内水 = saleSample.neishui
      const 灰分 = saleSample.huifen
      const 挥发分 = saleSample.huifafen
      const 全硫 = saleSample.quanliu
      const 固定碳 = saleSample.gudingtan
      const 热值兆焦 = saleSample.diweifareliangMj
      const 热值大卡 = saleSample.diweifareliangKc
      console.info('取样数量, 全水, 内水, 灰分, 挥发分, 全硫, 固定碳, 热值兆焦, 热值大卡', 取样数量, 全水, 内水, 灰分, 挥发分, 全硫, 固定碳, 热值兆焦, 热值大卡)
      eval(formula.jsScript)
      if (结果 === null) {
        ElMessage.error('公式有误，请手动填写')
        return
      }
      saleSample.saleAmount = math.halfUp(结果, 2)
    } catch {
      ElMessage.error('公式有误，结算失败')
      return
    }
  }
  param.list = saleSampleList
  ElMessage.info('结算中，请稍等')
  api.post('/backend/saleSample/settlement', param).then(() => {
    show.settlementFormulaDialog = false
    ElMessage.success('结算成功')
    commitFindList()
  })
}

</script>

<style lang="less"></style>